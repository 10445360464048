import Select from 'react-select';

function Servicios({cursosArray, setCursosArray, catalogoCursos, errors, setStatus}) {

	const emptyForm = [{id_curso:''}];

	const add = () => {
		setCursosArray([...cursosArray, ...emptyForm]);
		// console.log(cursosArray);
	}

	const remove = (index) =>{
		const tempData = [...cursosArray];
		tempData.splice(index,1);
		setCursosArray(serv => tempData);
	}

	const handleChange = (event, index) => {
		
		const tempData = [...cursosArray];
		tempData[index].id_curso = event.id_curso;
		setCursosArray(tempData);

    if(errors!==null){
      const errName = `servicios.${index}.id_curso`;
      if(errName in errors){
        const temp = errors;
        delete temp[errName];
        setStatus(status => ({...status, errors:temp}));
      }
    }

	}

  const verifyError = (name, index) =>{
    console.log (errors);
    if(errors !== null){
      const errName = `cursos.${index}.${name}`;
      console.log(errName);
      if(errName in errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name, index) =>{
    if(errors !== null){
      const errName = `cursos.${index}.${name}`;
      console.log(errName);
      if(errName in errors){
        return errors[errName][0];
      }
    }

    return "";
  }

  const getCurso = (index) => catalogoCursos.find(option => option.id_curso === cursosArray[index].id_curso);

	const firstElement = (form, index) => 
  <div className='row py-2'>
      <div className='col-8'>
          <small className="font-red">{getError("curso", index)}</small>
          <Select className={`z-10 z-auto ${verifyError("curso", index) ? "border-red" : ""}`}
            options={catalogoCursos} placeholder="Seleccione curso"
            value={getCurso(index)} onChange={(e) => handleChange(e,index,"curso","id_curso")}
            getOptionValue={(option) => `${option.id_curso}`}
            getOptionLabel={(option) => `${option.nombre}`}
          />
      </div>
      <div className='col-4'>
          <button className='btn btn-success w-100' onClick={()=>add()}>Agregar</button>
      </div>
    </div>;

    const formLayout = (form,index) => 
    <div className='row py-2'>
      <div className='col-8'>
          <small className="font-red">{getError("curso", index)}</small>
          <Select className={`z-10 z-auto ${verifyError("curso", index) ? "border-red" : ""}`}
          	options={catalogoCursos} placeholder="Seleccione curso"
            value={getCurso(index)} onChange={(e) => handleChange(e,index,"curso","id_curso")}
            getOptionValue={(option) => `${option.id_curso}`}
            getOptionLabel={(option) => `${option.nombre}`}
          />
      </div>
      <div className='col-4'>
        <button className='btn btn-danger w-100' onClick={()=>remove(index)}>Eliminar</button>
      </div>
    </div>;

	const forms = cursosArray.map((form, index) => {
		if(index === 0){
			return(firstElement(form,index));
		}
		return(formLayout(form,index));
	 });

	return forms;
}

export default Servicios;