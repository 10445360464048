import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Logo from './logo.png';
import ListGroup from 'react-bootstrap/ListGroup';
import axios from "axios";
import React, {useState, useEffect, useRef} from "react";
import {useParams} from "react-router-dom";
import moment from 'moment';

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import useDebounceEffect from './useDebounceEffect';
import canvasPreview from './canvasPreview';

import Select from 'react-select';
import Cursos from './Cursos';

const Upload = () => {

    const [data, setData] = useState([]);

    let { folioNum, tokenNum } = useParams();

    /* CROP */
  function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  } 

  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef(null)
  const [editarFoto, setEditarFoto] = useState(false)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(9 / 12)

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        const res = reader.result?.toString() || '';
        setImgSrc(res);
        setForm({...form, evidencias:{...form.evidencias, foto: res, foto_nombre: e.target.files[0].name}});
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          0,
        )
      }
    },
    100,
    [completedCrop, scale, 0],
  );

  /* */

    const emptyForm = {
        nombre_completo:'',
        curp:'',
        ocupacion:'',
        puesto:'',
        correo:'',
        sexo:'',
        edad:'',
        tipo_sangre:'',
        empresa:'',
        escolaridad:'',
        calle_num:'',
        municipio:'',
        estado:'',
        nombre_emergencia:'',
        telefono_emergencia:'',
        telefono:'',
        sexo_arr: [],
        escolaridad_arr: [],
        id_ocupacion: "",
        evidencias: {
          curp: '',
          curp_nombre: '',
          foto: '',
          foto_nombre: '',
          ine: '',
          ine_nombre: '',
        },
        cursos: [],
        acepta: false,
    };

    const sexos = [
        {
          'value': "Masculino",
          'text': 'Masculino'
        },
        {
          'value': "Femenino",
          'text': 'Femenino'
        }
      ];
    
      const escolaridades = [
        {
          'value': "Primaria",
          'text': 'Primaria'
        },
        {
          'value': "Secundaria",
          'text': 'Secundaria'
        },
        {
          'value': "Bachillerato",
          'text': 'Bachillerato'
        },
        {
          'value': "Carrera Tecnica",
          'text': 'Carrera Tecnica'
        },
        {
          'value': "Licenciatura",
          'text': 'Licenciatura'
        },
      ];

    const [form, setForm] = useState(emptyForm);

    const [loading, setLoading] = useState(false);
    const [folio, setFolio] = useState(folioNum);
    const [status, setStatus] = useState({status: null, message: null});

    const [cursos, setCursos] = useState([]);
    const [ocupaciones, setOcupaciones] = useState([]);
    const [clientes, setClientes] = useState([]);

    const getData = () =>{
        axios
            .get('https://paymun-api.adaptingweb.com/public/api/catalogo-cursantes-p')
            .then((response) => {
                console.log("RES", response)
                setStatus(response.data);
                setCursos(response.data.cursos);
                setOcupaciones(response.data.ocupaciones);
                setClientes(response.data.clientes);
            });
    }

    const handleChange = (e, name = "", value = "") =>{
        if(name === ""){
          if(e.target.name === "acepta"){
            setForm({...form, acepta: e.target.checked});
          } else {
            setForm({...form, [e.target.name]:e.target.value});
          }
        }else if(value === "id_ocupacion"){
            setForm({...form, [value]:e.id_ocupacion, [name]:e});
        }else if(value === "id_cliente"){
          setForm({...form, [value]:e.id_cliente, [name]:e});
        }else{
            setForm({...form, [value]:e.value, [name]:e});
        }
    
        if("errors" in status){
          const verifyName = name === "" ? e.target.name : name;
          if(verifyName in status.errors){
            const temp = status;
            delete temp.errors[verifyName];
            setStatus(temp);
          }
        }
      }

    useEffect(()=>{
        getData();
    },[])

    const getDiffInDays = () =>{
        var a = moment(data.fecha_termino);
        var b = moment(data.vigencia_fecha);

        if(a.isSameOrBefore(b)){
            const diff = b.diff(a, 'days');
            return diff;
        }

        return '0';
    }

    const borrarEvidencia = (name) => {
        setForm({...form, evidencias:{...form.evidencias, [name]: '', [`${name}_nombre`]: ''}});
      }
    
      const descargarEvidencia = (name) =>{
        const a = document.createElement("a"); // Create <a>
        a.href = form.evidencias[name]; // Image Base64 Goes here
        a.download = form.evidencias[`${name}_nombre`]; // File name Here
        a.click();
      }

      const handleFile = (event, name) => {

        const extension = event.target.files[0].type;
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function () {
            setForm({...form, evidencias:{...form.evidencias, [name]: reader.result, [`${name}_nombre`]: event.target.files[0].name}});
        };
    
      }


    const getCurso = cursos.find(option => option.id_curso === form.id_curso);
    const getSexo = sexos.find(option => option.value === form.sexo);
    const getEscolaridad = escolaridades.find(option => option.value === form.escolaridad);
    const getOcupacion = ocupaciones.find(option => option.id_ocupacion === form.id_ocupacion);
    const getCliente = clientes.find(option => parseInt(option.id_cliente,10) === parseInt(form.id_cliente,10));

    const [success, setSuccess] = useState(false);

    const verifyError = (name) =>{
        if("errors" in status){
          if(name in status.errors){
            return true;
          }
        }
    
        return false;
      }
    
      const getError = (name) =>{
        if("errors" in status){
          if(name in status.errors){
            return status.errors[name][0];
          }
        }
    
        return "";
      }

      const emptyCursosArray = [{id_curso:''}];
      const [cursosArray, setCursosArray] = useState(emptyCursosArray);
    
      const setCurArr = (d) =>{
        setCursosArray(d);
      }

      const doRequest = async e =>{
    
          setStatus({status: null, message: ""});
          setLoading(true);
          const tempData = form;
          tempData.cursos = cursosArray;
  
          await axios
              .post('https://paymun-api.adaptingweb.com/public/api/cursante-p', tempData)
              .then((response) => {
                  console.log(response.data);
                  if(response.data.status === "success"){
                      setStatus(response.data);
                      setSuccess(true);
                  }else{
                      if('errors' in response.data){
                          let mensaje = "";
                          Object.keys(response.data.errors).forEach(key =>{
                              mensaje += `${response.data.errors[key]}\n`;
                          });
                          setStatus({status: "error", message: mensaje});
                      }else{
                          setStatus({status: "error", message: "Ha ocurrido un error."});
                      }
                  }
              })
              .catch((error) => {
                  setStatus({status: "error", message: "Ha ocurrido un error. por favor intente de nuevo."});
                  setLoading(false);
              });
  
          setLoading(false);
        
      }

  return (
    <>
      <div className="container">
        <div className="row card my-5 p-4">
            <div className="col-12 col-lg-10 mx-auto">
                <div className="header text-center">
                    <img src={Logo} className="img-fluid mx-auto"/>
                    <h3 className='py-3 mb-0'>
                        Solicitud de cursos
                    </h3>
                </div>
                <hr/>
                {
                    status.status === "error" ?
                        <div className="alert alert-danger mt-4" role="alert">
                            <p className='pre-line'>{status.message}</p>
                        </div>
                        : ""
                }
                { !loading && !success ?
                    <div className="row">
                <div className="col-12">
                    <h4>Datos</h4>
                </div>
                <div className="col-4 mb-2">
                        <label>Nombre completo</label>
                        <input type="text" className="form-control"
                            placeholder="Nombre completo" name="nombre_completo" value={form.nombre_completo}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-4 mb-2">
                        <label>CURP</label>
                        <input type="text" className="form-control"
                            placeholder="CURP" name="curp" value={form.curp}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-4 mb-2">
                        <label>Ocupacion</label>
                        <Select className={`z-10 ${verifyError("id_ocupacion") ? "border-red" : ""}`}
                          options={ocupaciones} placeholder="Ocupación especifica"
                          value={getOcupacion} onChange={(e) => handleChange(e,"ocupacion_arr","id_ocupacion")}
                          getOptionValue={(option) => `${option.id_ocupacion}`}
                          getOptionLabel={(option) => `${option.nombre}`}
                        />
                    </div>
                    <div className="col-4 mb-2">
                        <label>Puesto</label>
                        <input type="text" className="form-control"
                            placeholder="Puesto" name="puesto" value={form.puesto}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-4 mb-2">
                        <label>Correo</label>
                        <input type="text" className="form-control"
                            placeholder="Correo" name="correo" value={form.correo}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-4 mb-2">
                        <label>Telefono</label>
                        <input type="text" className="form-control"
                            placeholder="Telefono" name="telefono" value={form.telefono}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-4 mb-2">
                        <label>Sexo</label>
                        <Select className={`z-10 ${verifyError("sexo") ? "border-red" : ""}`}
                          options={sexos} placeholder="Sexo"
                          value={getSexo} onChange={(e) => handleChange(e,"sexo_arr","sexo")}
                          getOptionValue={(option) => `${option.value}`}
                          getOptionLabel={(option) => `${option.text}`}
                        />
                    </div>
                    <div className="col-4 mb-2">
                        <label>Edad</label>
                        <input type="text" className="form-control"
                            placeholder="Edad" name="edad" value={form.edad}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-4 mb-2">
                    </div>
                    <div className="col-4 mb-2">
                        <label>Tipo de sangre</label>
                        <input type="text" className="form-control"
                            placeholder="Tipo de sangre" name="tipo_sangre" value={form.tipo_sangre}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-4 mb-2">
                        <label>Empresa</label>
                        <Select className={`z-10 ${verifyError("id_cliente") ? "border-red" : ""}`}
                          options={clientes} placeholder="Empresa"
                          value={getCliente} onChange={(e) => handleChange(e,"cliente_arr","id_cliente")}
                          getOptionValue={(option) => `${option.id_cliente}`}
                          getOptionLabel={(option) => `${option.nombre_comercial}`}
                        />
                    </div>
                    <div className="col-4 mb-2">
                        <label>Escolaridad</label>
                        <Select className={`z-10 ${verifyError("escolaridad") ? "border-red" : ""}`}
                          options={escolaridades} placeholder="Escolaridad"
                          value={getEscolaridad} onChange={(e) => handleChange(e,"escolaridad_arr","escolaridad")}
                          getOptionValue={(option) => `${option.value}`}
                          getOptionLabel={(option) => `${option.text}`}
                        />
                    </div>
                    <div className="col-4 mb-2">
                        <label>Calle y numero</label>
                        <input type="text" className="form-control"
                            placeholder="Calle y numero" name="calle_num" value={form.calle_num}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-4 mb-2">
                        <label>Municipio</label>
                        <input type="text" className="form-control"
                            placeholder="Municipio" name="municipio" value={form.municipio}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-4 mb-2">
                        <label>Estado</label>
                        <input type="text" className="form-control"
                            placeholder="Estado" name="estado" value={form.estado}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='col-12 mt-4'>
                      <h5>En caso de emergencia llamar a:</h5>
                    </div>
                    <div className="col-4 mb-2">
                        <label>Nombre</label>
                        <input type="text" className="form-control"
                            placeholder="Nombre" name="nombre_emergencia" value={form.nombre_emergencia}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-4 mb-2">
                        <label>Telefono</label>
                        <input type="text" className="form-control"
                            placeholder="Telefono" name="telefono_emergencia" value={form.telefono_emergencia}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-12 mt-4">
                        <h4>Archivos</h4>
                    </div>
                    <div className="col-6 mb-2">
                        <h5 className='mb-0'>INE</h5>
                        Archivo: 
                        {
                            form.evidencias.ine_nombre !== "" ? 
                            form.evidencias.ine_nombre : 
                            " No seleccionado."
                        }
                        {
                            form.evidencias.ine_nombre !== "" ? 
                            <div className='row'>
                                <div className='col-6'>
                                    <button className='btn btn-success w-100' onClick={()=>descargarEvidencia('ine')}>Descargar</button>
                                </div>
                                <div className='col-6'>
                                    <button className='btn btn-danger w-100' onClick={()=>borrarEvidencia('ine')}>Borrar</button>  
                                </div>
                            </div>
                             : 
                            <input type='file' className="btn btn-primary" onChange={(e)=>handleFile(e, 'ine')}/>
                        }
                        
                      </div>
                      <div className="col-6 mb-2">
                        <h5 className='mb-0'>CURP</h5>
                        Archivo: 
                        {
                            form.evidencias.curp_nombre !== "" ? 
                            form.evidencias.curp_nombre : 
                            " No seleccionado."
                        }
                        {
                            form.evidencias.curp_nombre !== "" ? 
                            <div className='row'>
                                <div className='col-6'>
                                    <button className='btn btn-success w-100' onClick={()=>descargarEvidencia('curp')}>Descargar</button>
                                </div>
                                <div className='col-6'>
                                    <button className='btn btn-danger w-100' onClick={()=>borrarEvidencia('curp')}>Borrar</button>
                                </div>
                            </div>
                             : 
                            <input type='file' className="btn btn-primary" onChange={(e)=>handleFile(e, 'curp')}/>
                        }
                      </div>
                      <div className="col-6 mb-2">
                        <h5 className='mb-0'>FOTO</h5>
                        Archivo: 
                          {
                            form.evidencias.foto_nombre ? 
                            form.evidencias.foto_nombre : 
                            " No seleccionado."
                          }
                          {
                            form.evidencias && !!form.evidencias.foto ?
                            <div className='col-12'>
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    aspect={aspect}
                                    disabled
                                    >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={form.evidencias.foto}
                                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, maxHeight: "300px"}}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>
                                <div className='row'>
                                    <div className='col-6 mb-2'>
                                        <button className='btn btn-primary w-100' onClick={()=>setScale(scale + 0.10)}>Acercar</button>
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <button className='btn btn-primary w-100' onClick={()=>setScale(scale - 0.10)}>Alejar</button>
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <button className='btn btn-success w-100' onClick={()=>descargarEvidencia('foto')}>Descargar imagen</button>
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <button className='btn btn-danger w-100' onClick={()=>borrarEvidencia('foto')}>Borrar imagen</button>
                                    </div>
                                </div>                              
                            </div> : <input type='file' className="btn btn-primary" accept="image/*" onChange={(e)=>onSelectFile(e)}/>
                        }
                      </div>
                        
                    {!!completedCrop && (
                        <canvas
                        ref={previewCanvasRef}
                        style={{
                            objectFit: 'contain',
                            width: "110px",
                            height: "145px",
                            position: "fixed",
                            top: 0,
                            right: "-300px",
                            margin: "auto",
                        }}
                        />
                    )}
                    <div className="col-12 mt-4">
                        <h4>Cursos</h4>
                    </div>
                    <div className='col-12 mb-4'>
                        <Cursos cursosArray={cursosArray} setCursosArray={setCurArr} 
                            catalogoCursos={cursos} errors={"errors" in status?status.errors:null}
                            setStatus={setStatus} />
                    </div>
                    <div className='col-12 mb-2'>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="acepta" checked={form.acepta} onChange={handleChange} id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                *Autoriza usted el uso de sus Datos para fines Internos de Difusión de Nuestros servicios y promociones, los cuales no serán vendidos ni transferidos a un tercero.
                            </label>
                        </div>
                    </div>
                    <div className="col-12 mb-2 mt-auto">
                        <button type="submit" className="btn btn-primary w-100" onClick={doRequest}>Enviar</button>
                    </div>
                    </div> :
                    ""
                }
                {
                    loading ? 
                    <div className='container text-center'>
                        
                        <div className="spinner-border m-5" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className=" mx-5">
                            Cargando...
                        </div>
                        
                        
                    </div> : ""
                }
                {
                    status.status === "error" ?
                        <div className="alert alert-danger mt-4" role="alert">
                            <p className='pre-line'>{status.message}</p>
                        </div>
                        : ""
                }
                {
                    success ?
                        <div className="alert alert-success mt-4" role="alert">
                            Se ha enviado su solicitud correctamente. Pronto nos pondremos en contacto con usted.
                        </div>
                        : ""
                }
            </div>
        </div>
      </div>
    </>
  )
};

export default Upload;